<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateCategory">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input v-model="category.name" class="form-control" id="name" required />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/categories";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      category: {
        name: "",
      },
      categoryId: this.$route.params.id,
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      if (!this.categoryId) {
        return;
      }
      find(this.categoryId).then((response) => {
        this.category = response.data;
      });
    },
    saveOrUpdateCategory() {
      if (this.categoryId) {
        this.updateCategory();
      } else {
        this.saveCategory();
      }
    },
    saveCategory() {
      create(this.category).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/categorias");
      });
    },
    updateCategory() {
      update(this.categoryId, this.category).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/categorias");
      });
    },
  },
};
</script>
